import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import AffiliateTerms from '../components/slides/affiliate-terms/AffiliateTerms';

const Tos = () => {
  return (
    <Layout>
      <SEO
        title="Affiliate Terms | Deadline Funnel"
        description="Learn how Deadline Funnel protects your privacy."
      />
      <AffiliateTerms />
    </Layout>
  )
};

export default Tos;